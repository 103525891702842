export default function acertaInit() {
  // Store Locale in localstorage and add it to url if missing
  const url = new URL(window.location.href);
  const searchParams = url.searchParams;
  if (searchParams.has("locale")){
    var locale = searchParams.get("locale")
    localStorage.setItem("locale", locale)
  }
  if (localStorage.getItem("locale") !== null) {
    var locale = localStorage.getItem("locale")
    url.searchParams.set("locale", locale)
    history.replaceState({}, null, url);
  }
  document.documentElement.setAttribute('lang', locale)
}

